import { ColorModeScript, ChakraProvider, extendTheme } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';

const rootElement = document.getElementById('root');

const theme = extendTheme({
  styles: {
    global: props => ({
      'html, body': {
        color: props.colorMode === 'dark' ? 'white' : 'gray.800',
      },
      a: {
        color: props.colorMode === 'dark' ? 'teal.300' : 'teal.500',
      },
    }),
  },
});

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <App />
      </ChakraProvider>
    </BrowserRouter>
  </StrictMode>,
  rootElement
);
