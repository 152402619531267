import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();

export function Success(title, description) {
  toast({
    title: title,
    description: description,
    status: 'success',
    duration: 3000,
    isClosable: true,
  });
}

export function Error(title, description) {
  return toast({
    title: title,
    description: description,
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
}

export function Warning(title, message) {
  return toast({
    title: title,
    description: message,
    status: 'warning',
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
}

export function Info(title, message) {
  return toast({
    title: title,
    description: message,
    status: 'info',
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  });
}
