import { basePath, apiVersion } from './config';

export function getCustromersApi(token) {
  const url = `${basePath}/${apiVersion}/customers`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return { message: err.message };
    });
}

export function getExCustromersApi(token, query) {
  const url = `${basePath}/${apiVersion}/customers`;

  const params = {
    method: 'PATCH',
    body: JSON.stringify(query),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(err => {
      return { message: err.message };
    });
}

export function postCustomerApi(token, data) {
  const url = `${basePath}/${apiVersion}/customer`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.customer) {
        return { response: 'Cliente creado correctamente' };
      }
      return { message: result.message };
    })
    .catch(err => {
      return { message: err.message };
    });
}

export function putCustomerApi(token, data, id) {
  const url = `${basePath}/${apiVersion}/customer/${id}`;
  const params = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then(response => {
      return response.json();
    })
    .then(result => {
      if (result.customer) {
        return { response: 'Cliente editado correctamente' };
      }
      return { message: result.message };
    })
    .catch(err => {
      return { message: err.message };
    });
}
