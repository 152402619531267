import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import useAuth from '../../hooks/useAuth';
import SidebarContent from './SidebarContent';
import MobileNav from './MobileNav';

const Master = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, isLoading } = useAuth();

  if (!user && !isLoading) {
    return (
      <>
        <Navigate replace to="/Login" />
      </>
    );
  }
  if (user && !isLoading) {
    return (
      <Box minH="100vh">
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="4">
          <Outlet />
        </Box>
      </Box>
    );
  }

  return null;
};

export default Master;
