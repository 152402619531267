import React, { useState, useEffect } from 'react';
import { getAccessTokenApi } from '../../api/auth';
import { getCustromersApi, getExCustromersApi } from '../../api/customer';
import CustomerManager from '../../components/customers/CustomerManager';
import CustomerFilter from '../../components/customers/CustomerFilter';

export default function Users() {
  const [customers, setCustomers] = useState([]);
  const [query, setQuery] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const token = getAccessTokenApi();

  useEffect(() => {
    setIsLoading(true);

    if (query.length === 0) {
      getCustromersApi(token).then(response => {
        if (response.message) {
          setCustomers([]);
        } else {
          setCustomers(response.customer);
        }
        setIsLoading(false);
      });
    } else {
      getExCustromersApi(token, query).then(response => {
        if (response.message) {
          setCustomers([]);
        } else {
          setCustomers(response.customer);
        }
        setIsLoading(false);
      });
    }
    setReload(false);
  }, [token, reload]);

  return (
    <>
      <CustomerFilter
        setReload={setReload}
        isLoading={isLoading}
        setQuery={setQuery}
      />
      <CustomerManager
        customers={customers}
        setReload={setReload}
        isLoading={isLoading}
      />
    </>
  );
}
