import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../ColorModeSwitcher';

import NoAvatar from '../../assets/img/png/no-avatar.png';
import useAuth from '../../hooks/useAuth';
import { getAvatarByIdApi } from '../../api/users';
import { logout } from '../../api/auth';
import { FiMenu, FiChevronDown } from 'react-icons/fi';

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const [avatar, setAvatar] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      getAvatarByIdApi(user.id).then(response => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const logoutUser = () => {
    logout();
    window.location.reload();
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      borderBottomWidth="1px"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold"
      >
        Logo
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar src={avatar ? avatar : NoAvatar} ml={5} mr={2} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">
                    {user.name} {user.lastname}
                  </Text>
                  <Text fontSize="xs">{user.role}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList>
              <MenuItem>Perfil</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => logoutUser()}>Salir...</MenuItem>
            </MenuList>
          </Menu>
          <Box>
            <ColorModeSwitcher />
          </Box>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default MobileNav;
