import React, { useState, useEffect } from 'react';
import { getAccessTokenApi } from '../../api/auth';
import { getUsersActiveApi } from '../../api/users';
import UserManager from '../../components/users/UserManager';

export default function Users() {
  const [usersActive, setUsersActive] = useState([]);
  const [usersInactive, setUsersInactive] = useState([]);
  const [reloadUsers, setReloadUsers] = useState(false);
  const token = getAccessTokenApi();

  useEffect(() => {
    getUsersActiveApi(token, true).then(response => {
      setUsersActive(response.users);
    });
    getUsersActiveApi(token, false).then(response => {
      setUsersInactive(response.users);
    });
    setReloadUsers(false);
  }, [token, reloadUsers]);

  return (
    <UserManager
      usersActive={usersActive}
      usersInactive={usersInactive}
      setReloadUsers={setReloadUsers}
    />
  );
}
