import { createStandaloneToast } from '@chakra-ui/react';

const toast = createStandaloneToast();


export function Success(options) {
  let init = {
    title: '',
    description: '',
    status: 'success',
    duration: 3000,
    isClosable: true,
  };

  let parms = Object.assign({}, init, options);

  toast(parms);
}

export function Error(options) {
  let init = {
    title: '',
    description: '',
    status: 'error',
    duration: 3000,
    isClosable: true,
    position: 'top-right',
  };

  let parms = Object.assign({}, init, options);

  return toast(parms);
}

