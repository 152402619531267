import React from 'react';
import { VStack, Spacer } from '@chakra-ui/react';
import EditUserForm from './EditUserForm';
import User from './User';

export default function UsersList(props) {
  const {
    users,
    onOpen,
    onClose,
    setModalTitle,
    setModalContent,
    setReloadUsers,
    setConfirmData,
    onOpenConfirm,
    onCloseConfirm,
    setOnExecute,
  } = props;

  const editUser = user => {
    setModalTitle(
      `Editar: ${user.name ? user.name : '...'} ${
        user.lastname ? user.lastname : '...'
      }`
    );
    setModalContent(
      <EditUserForm
        user={user}
        onClose={onClose}
        setReloadUsers={setReloadUsers}
      />
    );
    onOpen();
  };

  const deleteUser = (user, confirmData) => {
    setConfirmData(confirmData);
    onOpenConfirm();
  };

  return (
    <VStack mb={5}>
      <Spacer />
      {users.map((user, i, users) => (
        <User
          key={user.email}
          user={user}
          editUser={editUser}
          deleteUser={deleteUser}
          setReloadUsers={setReloadUsers}
          setConfirmData={setConfirmData}
          setOnExecute={setOnExecute}
          onCloseConfirm={onCloseConfirm}
          isLast={i + 1 === users.length}
        />
      ))}
    </VStack>
  );
}
