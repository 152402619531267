import { Box, CloseButton, Flex, Text, BoxProps } from '@chakra-ui/react';
import useAuth from '../../hooks/useAuth';
import NavItem from './NavItem';
import LinkItems from './LinkItems';

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { user } = useAuth();
  return (
    <Box
      transition="3s ease"
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      borderRightWidth="1px"
      {...rest}
    >
      <Flex
        h="20"
        alignItems="center"
        w={'100%'}
        justifyContent="center"
      >
        <Text fontSize="2xl" fontWeight="bold">
          Carne de Avestruz
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>

      {LinkItems.map(link =>
        link.role === '*' ? (
          <NavItem key={link.name} icon={link.icon} href={link.href}>
            {link.name}
          </NavItem>
        ) : link.role === user.role ? (
          <NavItem key={link.name} icon={link.icon} href={link.href}>
            {link.name}
          </NavItem>
        ) : (
          <></>
        )
      )}
    </Box>
  );
};

export default SidebarContent;
