import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  Select,
  Button,
  FormControl,
  Center,
  FormLabel,
  InputGroup,
  useColorModeValue,
  FormHelperText,
  Spacer,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { putCustomerApi } from '../../api/customer';
import { getAccessTokenApi } from '../../api/auth';
import { Success, Error } from '../../helpers/simpleToast';
import useAuth from '../../hooks/useAuth';

const schema = yup
  .object({
    name: yup.string().required('El nombre es requerido'),
    lastname: yup.string().required('El apellido es requerido'),
    email: yup
      .string()
      .email('El email esta mal formado')
      .required('El email es requerido'),
    phone: yup.string().required('El telefono es requerido'),
    delivery: yup.object({
      address: yup.string().required('La direccion es requerida'),
      city: yup.string().required('La ciudad es requerida'),
      state: yup.string().required('El estado es requerido'),
    }),
  })
  .required();

export default function EditCustomerForm(props) {
  const { customer, setReload, onClose } = props;
  const { user, isLoading } = useAuth();
  const [customerData, setCustomerData] = useState({});

  useEffect(() => {
    setCustomerData(customer);
  }, [customer]);

  const edit = data => {
    data.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
    data.lastname = data.lastname.charAt(0).toUpperCase() + data.lastname.slice(1);

    const accesToken = getAccessTokenApi();
    putCustomerApi(accesToken, data, data._id).then(result => {
      Success('', result.message);
      setReload(true);
      onClose();
    });
  };

  return <EditForm customer={customer} edit={edit} />;
}

function EditForm(props) {
  const { edit, customer } = props;
  const { register, handleSubmit, formState, reset, setFocus } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(customer);
  }, [customer]);

  const { errors, isSubmitting } = formState;
  const color = useColorModeValue('red.500', 'red.200');

  return (
    <form onSubmit={handleSubmit(edit)}>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Nombre del Cliente
        </FormLabel>
        <InputGroup>
          <Input placeholder="Nombre" mr="5" {...register('name')} />
          <Input placeholder="Apellidos" {...register('lastname')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.name?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.lastname?.message}
        </FormHelperText>
      </FormControl>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Contacto
        </FormLabel>
        <InputGroup>
          <Input
            mr="5"
            placeholder="Correo electronico"
            {...register('email')}
          />
          <Input placeholder="telefono" {...register('phone')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.email?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.phone?.message}
        </FormHelperText>
      </FormControl>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Direccion
        </FormLabel>
        <InputGroup>
          <Input
            placeholder="Calle, numero, colonia"
            {...register('delivery.address')}
          />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.delivery?.address?.message}
        </FormHelperText>
        <InputGroup>
          <Input placeholder="ciudad" mr="5" {...register('delivery.city')} />
          <Input placeholder="codigo postal" {...register('delivery.zip')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.delivery?.city?.message}
        </FormHelperText>
        <Select placeholder="Estado" {...register('delivery.state')}>
          <option value="cdmx">CDMX</option>
          <option value="Yucatan">Yucatan</option>
        </Select>

        <FormHelperText color={color} textAlign="left">
          {errors.delivery?.state?.message}
        </FormHelperText>
      </FormControl>

      <Center>
        <Spacer />
        <Button mt="5" mb="2" type="submit">
          Guardar cambios
        </Button>
        <Spacer />
        <Button
          mt="5"
          mb="2"
          colorScheme="red"
          onClick={() => {
            reset();
            setFocus('name', { shouldSelect: true });
          }}
        >
          Limpiar la forma
        </Button>
        <Spacer />
      </Center>
    </form>
  );
}
