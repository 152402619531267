import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiDollarSign,
} from 'react-icons/fi';

import {
  FaCommentDollar,
  FaDollarSign,
  FaFileInvoiceDollar,
  FaUserTie,
  FaUsers,
} from 'react-icons/fa';

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
  role: string;
}

const LinkItems: Array<LinkItemProps> = [
  { name: 'Tablero', icon: FiHome, href: '/secure', role: '*' },
  {
    name: 'Clientes',
    icon: FaUserTie,
    href: '/secure/clientes',
    role: '*',
  },
  {
    name: 'Cotizaciones',
    icon: FaCommentDollar,
    href: '/secure/nueva-solicitud',
    role: '*',
  },
  {
    name: 'Pedidos',
    icon: FaDollarSign,
    href: '/secure/nueva-solicitud',
    role: '*',
  },
  {
    name: 'Facturas',
    icon: FaFileInvoiceDollar,
    href: '/secure/nueva-solicitud',
    role: '*',
  },
  {
    name: 'Usuarios',
    icon: FaUsers,
    href: '/secure/usuarios',
    role: 'admin',
  },
];

export default LinkItems;
