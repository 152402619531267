import { useMatch, useResolvedPath } from 'react-router-dom';
import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';

interface NavItemProps extends FlexProps {
  icon: IconType;
  href: ReactText;
  children: ReactText;
}
const NavItem = ({ icon, children, href, ...rest }: NavItemProps) => {
  let resolved = useResolvedPath(href);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <Link
      href={match ? '#' : href}
      style={{
        textDecoration: 'none',
      }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        role="group"
        cursor={match ? 'default' : 'pointer'}
        _hover={
          match
            ? {}
            : {
                bg: 'blue.400',
                color: 'white',
              }
        }
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={
              match
                ? {}
                : {
                    color: 'white',
                  }
            }
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};
export default NavItem;
