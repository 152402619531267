import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  VStack,
  StackDivider,
  Center,
  Spacer,
  Button,
  Switch,
  useDisclosure,
} from '@chakra-ui/react';
import UsersList from './UsersList';
import Modal from '../../components/modal';
import Confirm from '../../components/confirm';
import AddUserForm from './AddUserForm';

export default function UserManager(props) {
  const { usersActive, usersInactive, setReloadUsers } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const cancelRef = React.useRef();
  
  const [viewUsersActives, setViewUsersActives] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [confirmData, setConfirmData] = useState({
    title: '',
    content: '',
    ok: '',
    cancel: '',
  });

  const [onExecute, setOnExecute] = useState(
    () => () => console.log('execute!')
  );

  const addUserModal = () => {
    setModalTitle('Creando nuevo usuario');
    setModalContent(
      <AddUserForm onClose={onClose} setReloadUsers={setReloadUsers} />
    );
    onOpen();
  };

  return (
    <Box w="100%">
      <VStack divider={<StackDivider />} align="stretch">
        <Box padding="2" borderWidth={1} borderRadius={8} boxShadow="lg">
          <Center>
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="email-alerts" mb="0">
                {viewUsersActives ? 'Usuarios Activos' : 'Usuarios Inactivos'}
              </FormLabel>
              <Switch
                defaultChecked
                onChange={() => setViewUsersActives(!viewUsersActives)}
              />
            </FormControl>
            <Spacer />
            <Button onClick={addUserModal}>Nuevo Usuario</Button>
          </Center>

          <UsersList
            users={viewUsersActives ? usersActive : usersInactive}
            onClose={onClose}
            onOpen={onOpen}
            setModalTitle={setModalTitle}
            setModalContent={setModalContent}
            setReloadUsers={setReloadUsers}
            setConfirmData={setConfirmData}
            onOpenConfirm={onOpenConfirm}
            onCloseConfirm={onCloseConfirm}
            setOnExecute={setOnExecute}
          />

          <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
            {modalContent}
          </Modal>
          <Confirm
            title={confirmData.title}
            content={confirmData.content}
            okText={confirmData.ok}
            cancelText={confirmData.cancel}
            isOpen={isOpenConfirm}
            onClose={onCloseConfirm}
            cancelRef={cancelRef}
            onExecute={onExecute}
          />
        </Box>
      </VStack>
    </Box>
  );
}
