import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Link,
  Avatar,
  FormControl,
  FormHelperText,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../utils/constants';
import { signInApi } from '../../api/users';
import { Success } from '../../helpers/toast';
import { Error } from '../../helpers/simpleToast';

const schema = yup
  .object({
    email: yup
      .string()
      .email('El mail esta mal formado')
      .required('El email es requerido'),
    password: yup.string().required('El password es requerido'),
  })
  .required();

const LoginForm = () => {
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors, isSubmitting } = formState;

  const CFaUserAlt = chakra(FaUserAlt);
  const CFaLock = chakra(FaLock);

  const [showPassword, setShowPassword] = useState(false);
  const onShowClick = () => setShowPassword(!showPassword);

  const onSubmit = async data => {
    const result = await signInApi(data);

    console.log(result.message);

    if (result.message) {
      Error('Error...', 'Usuario/Password no reconocido');
    } else {
      const { accessToken, refreshToken } = result;
      localStorage.setItem(ACCESS_TOKEN, accessToken);
      localStorage.setItem(REFRESH_TOKEN, refreshToken);
      Success({
        title: 'Acceso Ok...',
        description: 'Sera redireccionado al portal',
        onCloseComplete: () => {
          window.location.href = '/secure';
        },
      });
    }
  };

  return (
    <Stack flexDir="column" alignItems="center">
      <Avatar size="2xl" />
      <Heading>Acceso Sistema</Heading>
      <Box minW={{ base: '90%', md: '468px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4} p="1rem" boxShadow="md">
            <FormControl>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<CFaUserAlt />}
                />
                <Input
                  {...register('email')}
                  type="email"
                  placeholder="Correo electronico"
                />
              </InputGroup>
              <FormHelperText color="red" textAlign="left">
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <InputGroup>
                <InputLeftElement pointerEvents="none" children={<CFaLock />} />
                <Input
                  {...register('password')}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Contraseña"
                  isInvalid={errors.password}
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={onShowClick}>
                    {showPassword ? 'Ocultar' : 'Mostrar'}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormHelperText color="red" textAlign="left">
                {errors.password?.message}
              </FormHelperText>
              <FormHelperText textAlign="right">
                <Link>Recuperar contraseña?</Link>
              </FormHelperText>
            </FormControl>
            <Button
              borderRadius={0}
              type="submit"
              variant="solid"
              width="full"
              isDisabled={isSubmitting}
            >
              {isSubmitting ? <Spinner></Spinner> : 'Acceso'}
            </Button>
          </Stack>
        </form>
      </Box>
    </Stack>
  );
};

export default LoginForm;
