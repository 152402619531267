import React from 'react';
import {
  Modal as ModalChakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Divider,
} from '@chakra-ui/react';

export default function Modal(props) {
  const { children, title, isOpen, onClose } = props;

  return (
    <ModalChakra onClose={onClose} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ModalChakra>
  );
}
