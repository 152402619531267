import React, { useState, useEffect } from 'react';
import {
  Button,
  Avatar,
  Center,
  Box,
  Text,
  Spacer,
  Divider,
  HStack,
  VStack,
  StackDivider,
  Link,
  Flex,
} from '@chakra-ui/react';

import { StarIcon } from '@chakra-ui/icons';
import NoAvatar from '../../assets/img/png/no-avatar.png';
import {
  FaUserEdit,
  FaUserSlash,
  FaLock,
  FaUnlock,
  FaCommentDollar,
  FaDollarSign,
  FaFileInvoiceDollar,
} from 'react-icons/fa';

import { getAvatarApi } from '../../api/users';

export default function Customer(props) {
  const { customer, edit, del, isLast } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (customer.owner.avatar) {
      getAvatarApi(customer.owner.avatar).then(response => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [customer.owner]);

  return (
    <>
      <Center w={'100%'} pt={3}>
        <Flex w={'100%'}>
          <Avatar src={avatar ? avatar : NoAvatar} ml={5} />
          <Box flex={1} ml={5}>
            <Text fontSize="2xl" fontWeight="bolder">
              {customer.name ? customer.name : '...'}{' '}
              {customer.lastname ? customer.lastname : '...'}
            </Text>
            <Link href={'mailto:' + customer.email} isExternal>
              <Text>{customer.email}</Text>
            </Link>
            <Link href={'tel:' + customer.phone} isExternal>
              <Text>{customer.phone}</Text>
            </Link>
          </Box>
          <Box flex={1} ml={5}>
            <Text fontSize="m">
              {customer.delivery.address ? customer.delivery.address : ''}
            </Text>
            <Text fontSize="m">
              {customer.delivery.zip ? customer.delivery.zip : ''}
            </Text>
            <Text fontSize="m">
              {customer.delivery.state ? customer.delivery.state : ''}
            </Text>
            <Text fontSize="m">
              {customer.delivery.country ? customer.delivery.country : ''}
            </Text>
          </Box>
          <Box flex={1} ml={10}>
            <Text fontSize="xl" align="top" fontWeight="bold">
              {customer.owner.name ? customer.owner.name : '...'}{' '}
              {customer.owner.lastname ? customer.owner.lastname : '...'}
            </Text>
            <Text as="cite">{customer.sold ? 'Cliente' : 'Prospecto'}</Text>
            <Box display="flex" mt="2" alignItems="center">
              {Array(5)
                .fill('')
                .map((_, i) => (
                  <StarIcon
                    key={i}
                    color={
                      i < (customer.rate ? customer.rate : 0)
                        ? 'teal.500'
                        : 'gray.300'
                    }
                  />
                ))}
            </Box>
          </Box>
        </Flex>
        <Spacer />
        <HStack mr={5} p={1} borderWidth="1px" borderRadius="lg">
          <VStack spacing={1}>
            <Button size="sm" colorScheme="blue" m={1}>
              <FaCommentDollar />
            </Button>
            <Box>
              <Text>0</Text>
            </Box>
          </VStack>
          <VStack spacing={1}>
            <Button size="sm" colorScheme="blue" m={1}>
              <FaDollarSign />
            </Button>
            <Box>
              <Text>0</Text>
            </Box>
          </VStack>
          <VStack spacing={1}>
            <Button size="sm" colorScheme="blue" m={1}>
              <FaFileInvoiceDollar />
            </Button>
            <Box>
              <Text>0</Text>
            </Box>
          </VStack>
        </HStack>
        <Button
          size="lg"
          colorScheme="green"
          m={1}
          onClick={() => {
            edit(customer);
          }}
        >
          <FaUserEdit size={'30'} />
        </Button>
        <Button
          size="lg"
          colorScheme="red"
          m={1}
          onClick={() => {
            del(customer);
          }}
        >
          <FaUserSlash size={'30'} />
        </Button>
      </Center>
      {isLast ? <></> : <Divider />}
    </>
  );
}
