import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  Select,
  Button,
  FormControl,
  Center,
  FormLabel,
  InputGroup,
  FormHelperText,
  Spacer,
  Flex,
  useColorModeValue,
  Avatar,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDropzone } from 'react-dropzone';
import NoAvatar from '../../assets/img/png/no-avatar.png';
import { updateUserApi, uploadAvatarApi, getAvatarApi } from '../../api/users';
import { getAccessTokenApi } from '../../api/auth';
import { Success, Error } from '../../helpers/simpleToast';

const schema = yup
  .object({
    name: yup.string().required('El nombre es requerido'),
    lastname: yup.string().required('El apellido es requerido'),
    email: yup
      .string()
      .email('El email esta mal formado')
      .required('El email es requerido'),
    role: yup.string().required('El email es requerido'),
    password: yup
      .string()
      .min(8, 'El password no puede ser menor a 8 caracteres')
      .required('El password es requerido'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'El password no es igual'),
  })
  .required();

export default function EditUserForm(props) {
  const { user, onClose, setReloadUsers } = props;
  const [avatar, setAvatar] = useState(null);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    setUserData({
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      avatar: user.avatar,
    });
  }, [user]);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then(response => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  useEffect(() => {
    if (avatar) {
      setUserData({ ...userData, avatar: avatar.file });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avatar]);

  const updateUser = data => {
    const token = getAccessTokenApi();
    let userUpdate = data;

    if (typeof userUpdate.avatar === 'object') {
      uploadAvatarApi(token, userUpdate.avatar, user._id).then(response => {
        userUpdate.avatar = response.avatarName;
        updateUserApi(token, userUpdate, user._id).then(result => {
          Success('', result.message);
          onClose();
          setReloadUsers(true);
        });
      });
    } else {
      updateUserApi(token, userUpdate, user._id).then(result => {
        Success('', result.message);
        setReloadUsers(true);
        onClose();
      });
    }
  };

  return (
    <>
      <UploadAvatar avatar={avatar} setAvatar={setAvatar} />
      <EditForm userData={userData} updateUser={updateUser} />
    </>
  );
}

function UploadAvatar(props) {
  const { avatar, setAvatar } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (avatar) {
      if (avatar.preview) {
        setAvatarUrl(avatar.preview);
      } else {
        setAvatarUrl(avatar);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [avatar]);

  const onDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];
      setAvatar({ file, preview: URL.createObjectURL(file) });
    },
    [setAvatar]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    noKeyboard: true,
    onDrop,
  });

  return (
    <Flex mb={5}>
      <Spacer />
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Avatar size="2xl" src={NoAvatar} />
        ) : (
          <Avatar size="2xl" src={avatarUrl ? avatarUrl : NoAvatar} />
        )}
      </div>
      <Spacer />
    </Flex>
  );
}

function EditForm(props) {
  const { userData, updateUser } = props;
  const { register, handleSubmit, formState, reset, setFocus } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => {
      return props.userData;
    }, [props]),
  });

  useEffect(() => {
    reset(props.userData);
  }, [props.userData]);

  const { errors, isSubmitting } = formState;
  const color = useColorModeValue('red.500', 'red.200');

  return (
    <form onSubmit={handleSubmit(updateUser)}>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Nombre del Usuario
        </FormLabel>
        <InputGroup>
          <Input placeholder="Nombre" mr="5" {...register('name')} />
          <Input placeholder="Apellidos" {...register('lastname')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.name?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.lastname?.message}
        </FormHelperText>
      </FormControl>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Correo Electronico
        </FormLabel>
        <InputGroup>
          <Input placeholder="Correlo electronico" {...register('email')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.email?.message}
        </FormHelperText>
      </FormControl>

      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Rol del Usuario
        </FormLabel>
        <Select placeholder="Selecióna un rol" {...register('role')}>
          <option value="admin">Administrador</option>
          <option value="payer">Pagador</option>
          <option value="reviewer">Revisor</option>
          <option value="uploader">Solicitante</option>
        </Select>
        <FormHelperText color={color} textAlign="left">
          {errors.role?.message}
        </FormHelperText>
      </FormControl>

      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Contraseña
        </FormLabel>
        <InputGroup>
          <Input
            mr="5"
            type="password"
            placeholder="Contraseña"
            {...register('password')}
          />
          <Input
            type="password"
            placeholder="Repetir contraseña"
            {...register('repeatPassword')}
          />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.password?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.repeatPassword?.message}
        </FormHelperText>
      </FormControl>
      <Center>
        <Spacer />
        <Button mt="5" mb="2" type="submit">
          Salvar cambios
        </Button>
        <Spacer />
        <Button
          mt="5"
          mb="2"
          colorScheme="red"
          onClick={() => {
            reset();
          }}
        >
          regresar valores
        </Button>
        <Spacer />
      </Center>
    </form>
  );
}
