import React from 'react';
import {
  Box,
  Grid
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';

const Basic = ({ children }) => {
  
  return (
    <>
      <Box textAlign="center">
        <Grid minH="100vh" p={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
          {children}
        </Grid>
      </Box>
    </>
  );
};

export default Basic;
