import React from 'react';
import {
  Box,
  FormControl,
  HStack,
  Spacer,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Link,
} from '@chakra-ui/react';

import { FaSyncAlt, FaSearch } from 'react-icons/fa';

export default function CustomerManager(props) {
  const { setReload, isLoading, setQuery } = props;

  const search = event => {
    let str = event.target.value;
    if (str.length > 2) {
      if (str.split(' ').length === 1) {
        setQuery({
          name: { $regex: '.*' + str.split(' ')[0] + '.*', $options: 'i' },
        });
      } else {
        setQuery({
          $and: [
            {
              name: { $regex: '.*' + str.split(' ')[0] + '.*', $options: 'i' },
            },
            {
              lastname: {
                $regex: '.*' + str.split(' ')[1] + '.*',
                $options: 'i',
              },
            },
          ],
        });
      }
      setReload(true);
    } else if (str.length === 0) {
      setReload(true);
    } else {
      setQuery([]);
    }
  };

  return (
    <Box
      w="100%"
      padding="2"
      borderWidth={1}
      borderRadius={8}
      boxShadow="lg"
      mb={5}
    >
      <HStack align="stretch">
        <FormControl mb={2}>
          <InputGroup>
            <Input placeholder="Buscar" mr="5" onChange={search} />
            <InputLeftElement color={'gray.500'} mr={25}>
              <FaSearch></FaSearch>
            </InputLeftElement>
          </InputGroup>
        </FormControl>
        <Button pl={'25px'} pr={'25px'}>
          Busqueda Avanzada
        </Button>
        <Spacer></Spacer>
        <Button
          onClick={() => {
            setReload(true);
          }}
        >
          {isLoading ? <Spinner /> : <FaSyncAlt size={20}></FaSyncAlt>}
        </Button>
      </HStack>
    </Box>
  );
}
