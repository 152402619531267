import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Input,
  Select,
  Button,
  FormControl,
  Center,
  FormLabel,
  InputGroup,
  useColorModeValue,
  FormHelperText,
  Spacer,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { signUpAdminApi } from '../../api/users';
import { getAccessTokenApi } from '../../api/auth';
import { Success, Error } from '../../helpers/simpleToast';

const schema = yup
  .object({
    name: yup.string().required('El nombre es requerido'),
    lastname: yup.string().required('El apellido es requerido'),
    email: yup
      .string()
      .email('El email esta mal formado')
      .required('El email es requerido'),
    role: yup.string().required('El email es requerido'),
    password: yup
      .string()
      .min(8, 'El password no puede ser menor a 8 caracteres')
      .required('El password es requerido'),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'El password no es igual'),
  })
  .required();

export default function AddUserForm(props) {
  const { setReloadUsers, onClose } = props;

  const addUser = data => {
    console.log(data);
    const accesToken = getAccessTokenApi();

    signUpAdminApi(accesToken, data)
      .then(response => {
        Success('', response);
        onClose();
        setReloadUsers(true);
      })
      .catch(err => {
        Error('', err);
      });
  };

  return <AddForm addUser={addUser} />;
}

function AddForm(props) {
  const { addUser } = props;
  const { register, handleSubmit, formState, reset, setFocus } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors, isSubmitting } = formState;
  const color = useColorModeValue('red.500', 'red.200');

  return (
    <form onSubmit={handleSubmit(addUser)}>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Nombre del Usuario
        </FormLabel>
        <InputGroup>
          <Input placeholder="Nombre" mr="5" {...register('name')} />
          <Input placeholder="Apellidos" {...register('lastname')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.name?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.lastname?.message}
        </FormHelperText>
      </FormControl>
      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Correo Electronico
        </FormLabel>
        <InputGroup>
          <Input placeholder="Correlo electronico" {...register('email')} />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.email?.message}
        </FormHelperText>
      </FormControl>

      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Rol del Usuario
        </FormLabel>
        <Select placeholder="Selecióna un rol" {...register('role')}>
          <option value="Administrador">Administrador</option>
          <option value="Distribuidor">Distribuidor</option>
          <option value="Agente">Agente</option>
        </Select>
        <FormHelperText color={color} textAlign="left">
          {errors.role?.message}
        </FormHelperText>
      </FormControl>

      <FormControl mb={2}>
        <FormLabel htmlFor="id" minW={'150px'}>
          Contraseña
        </FormLabel>
        <InputGroup>
          <Input
            mr="5"
            type="password"
            placeholder="Contraseña"
            {...register('password')}
          />
          <Input
            type="password"
            placeholder="Repetir contraseña"
            {...register('repeatPassword')}
          />
        </InputGroup>
        <FormHelperText color={color} textAlign="left">
          {errors.password?.message}
        </FormHelperText>
        <FormHelperText color={color} textAlign="left">
          {errors.repeatPassword?.message}
        </FormHelperText>
      </FormControl>
      <Center>
        <Spacer />
        <Button mt="5" mb="2" type="submit">
          Crear Usuario
        </Button>
        <Spacer />
        <Button
          mt="5"
          mb="2"
          colorScheme="red"
          onClick={() => {
            reset();
            setFocus('name', { shouldSelect: true });
          }}
        >
          Limpiar la forma
        </Button>
        <Spacer />
      </Center>
    </form>
  );
}
