import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  VStack,
  StackDivider,
  Center,
  Spacer,
  Button,
  Switch,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import CustomerLists from './CustomerList';
import Modal from '../../components/modal';
import Confirm from '../../components/confirm';
import AddCustomerForm from './AddCustomerForm';

export default function CustomerManager(props) {
  const { customers, setReload, isLoading } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenConfirm,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure();
  const cancelRef = React.useRef();

  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [confirmData, setConfirmData] = useState({
    title: '',
    content: '',
    ok: '',
    cancel: '',
    onExecute: () => {},
  });

  const addCustomerModal = () => {
    setModalTitle('Creando nuevo Cliente');
    setModalContent(
      <AddCustomerForm onClose={onClose} setReload={setReload} />
    );
    onOpen();
  };

  return (
    <Box w="100%">
      <VStack divider={<StackDivider />} align="stretch">
        <Box padding="2" borderWidth={1} borderRadius={8} boxShadow="lg">
          {isLoading ? (
            <Center>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
          ) : (
            <>
              <Center>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-alerts" mb="0"></FormLabel>
                </FormControl>
                <Spacer />
                <Button onClick={addCustomerModal}>Nuevo Cliente</Button>
              </Center>
              <CustomerLists
                customers={customers}
                onClose={onClose}
                onOpen={onOpen}
                setModalTitle={setModalTitle}
                setModalContent={setModalContent}
                setReload={setReload}
              ></CustomerLists>
            </>
          )}
          <Modal title={modalTitle} isOpen={isOpen} onClose={onClose}>
            {modalContent}
          </Modal>
          <Confirm
            title={confirmData.title}
            content={confirmData.content}
            okText={confirmData.ok}
            cancelText={confirmData.cancel}
            isOpen={isOpenConfirm}
            onClose={onCloseConfirm}
            cancelRef={cancelRef}
            onExecute={confirmData.onExecute}
          />
        </Box>
      </VStack>
    </Box>
  );
}
