import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import LoginForm from '../../components/login/LoginForm';

const Login = () => {
  const { user, isLoading } = useAuth();

  if (user && !isLoading) {
    return (
      <>
        <Navigate replace to="/secure" />
      </>
    );
  }

  return <LoginForm />;
};

export default Login;
