import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from './providers/AuthProvider';

// Layout
import Master from './layouts/master/Master';
import Basic from './layouts/Basic';

// Public Pages
import Login from './pages/public/Login';
import Error404 from './pages/public/Error404';

// Private Pages
import Home from './pages/private/Home';
import Users from './pages/private/Users';
import Customers from './pages/private/Customers';

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/secure" element={<Master />}>
          <Route index element={<Home />} />
          <Route path="clientes" element={<Customers />} />
          <Route path="usuarios" element={<Users />} />
        </Route>

        <Route
          path="/"
          exact={true}
          element={
            <Basic>
              <Login />
            </Basic>
          }
        />
        <Route
          path="/login"
          element={
            <Basic>
              <Login />
            </Basic>
          }
        />
        <Route
          path="*"
          element={
            <Basic>
              <Error404 />
            </Basic>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
