import React from 'react';
import { VStack, Spacer } from '@chakra-ui/react';
import EditCustomerForm from './EditCustomerForm';
import Customer from './Customer';

export default function CustomerList(props) {
  const {
    customers,
    onOpen,
    onClose,
    setModalTitle,
    setModalContent,
    setReload,
  } = props;

  const edit = customer => {
    setModalTitle(
      `Editar: ${customer.name ? customer.name : '...'} ${
        customer.lastname ? customer.lastname : '...'
      }`
    );
    setModalContent(
      <EditCustomerForm
        customer={customer}
        onClose={onClose}
        setReload={setReload}
      />
    );
    onOpen();
  };

  const del = customer => {
    console.log(customer);
  };

  return (
    <VStack mb={5}>
      <Spacer />
      {customers?.map((customer, i) => (
        <Customer
          key={customer.email}
          customer={customer}
          edit={edit}
          del={del}
          isLast={i + 1 === customers.length}
        />
      ))}
    </VStack>
  );
}
