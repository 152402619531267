import React, { useState, useEffect } from 'react';
import {
  Button,
  Avatar,
  Center,
  Box,
  Text,
  Spacer,
  Divider,
} from '@chakra-ui/react';
import { getAvatarApi, activateUserApi, deleteUserApi } from '../../api/users';
import { Success, Error } from '../../helpers/simpleToast';
import NoAvatar from '../../assets/img/png/no-avatar.png';
import { getAccessTokenApi } from '../../api/auth';
import { FaUserEdit, FaUserSlash, FaLock, FaUnlock } from 'react-icons/fa';

export default function User(props) {
  const {
    user,
    editUser,
    deleteUser,
    setReloadUsers,
    setOnExecute,
    onCloseConfirm,
    isLast,
  } = props;
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (user.avatar) {
      getAvatarApi(user.avatar).then(response => {
        setAvatar(response);
      });
    } else {
      setAvatar(null);
    }
  }, [user]);

  const desactivateUser = () => {
    const accesToken = getAccessTokenApi();
    activateUserApi(accesToken, user._id, false)
      .then(response => {
        Success('', response);
        setReloadUsers(true);
      })
      .catch(err => {
        Error('', err);
      });
  };

  const activateUser = () => {
    const accesToken = getAccessTokenApi();
    activateUserApi(accesToken, user._id, true)
      .then(response => {
        Success('', response);
        setReloadUsers(true);
      })
      .catch(err => {
        Error('', err);
      });
  };

  const execute = () => {
    const accesToken = getAccessTokenApi();
    deleteUserApi(accesToken, user._id)
      .then(response => {
        Success('', response);
        onCloseConfirm();
        setReloadUsers(true);
      })
      .catch(err => {
        Error('', err);
        onCloseConfirm();
      });
  };

  const showDeleteConfirm = () => {
    setOnExecute(() => execute);
    deleteUser(user, {
      title: 'Eliminando usuario',
      content: `¿Estas seguro que quieres eliminar a ${user.email}?`,
      ok: 'Eliminar',
      cancel: 'Cancelar',
    });
  };

  return (
    <>
      <Center w={'100%'} pt={3}>
        <Avatar src={avatar ? avatar : NoAvatar} ml={5} mr={5} />
        <Box>
          <Text fontSize="2xl">
            {user.name ? user.name : '...'}{' '}
            {user.lastname ? user.lastname : '...'}
          </Text>
          <Text as="cite">
            {' '}
            {user.email} | {user.role}
          </Text>
        </Box>
        <Spacer />
        {user.active ? (
          <>
            <Button
              size="sm"
              colorScheme="twitter"
              onClick={() => editUser(user)}
              m={1}
            >
              <FaUserEdit />
            </Button>

            <Button size="sm" colorScheme="red" onClick={desactivateUser} m={1}>
              <FaLock />
            </Button>
          </>
        ) : (
          <Button size="sm" colorScheme="red" onClick={activateUser} m={1}>
            <FaUnlock />
          </Button>
        )}
        <Button size="sm" colorScheme="red" onClick={showDeleteConfirm} m={1}>
          <FaUserSlash />
        </Button>
      </Center>
      {isLast ? <></> : <Divider />}
    </>
  );
}
